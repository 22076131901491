import { FC } from "react";

import { GenericType } from "../../../types";
import DropDownList from "../DropDownList";

type Prop = {
  properties: GenericType;
};

const PPADropdownList: FC<Prop> = ({ properties }) => {
  console.log(properties);
  return (
    <DropDownList
      title=""
      subTitleKey="title"
      data={properties.dataSet}
      defaultExpand={false}
      properties={{
        buyer: { key: "Buyer" },
        seller: { key: "Seller" },
        sizeMW: { key: "Size MW" },
        duration: { key: "Duration years" },
        type: { key: "PPA type" },
        startYear: { key: "Contract start year" },
        endYear: { key: "Contract end year" },
        notes: { key: "Notes" },
      }}
    />
  );
};

export default PPADropdownList;
